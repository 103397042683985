const supportLink = {
  href: 'bWFpbHRvOnN1cHBvcnRAYmxpc3Nib29rLmNvbT9zdWJqZWN0PUJsaXNzYm9vayBFcnJvcg==',
  text: 'c3VwcG9ydEBibGlzc2Jvb2suY29t',
}

const fixSupportLink = (node: HTMLLinkElement) => {
  node.href = atob(supportLink.href)
  node.innerHTML = atob(supportLink.text)
}

export const fixSupportLinks = () => {
  Array.prototype.forEach.call(
    document.querySelectorAll('a.support'),
    fixSupportLink,
  )
}
